import React from 'react'

import Layout from "../common/layout"
import { getCustomerDetails } from "./api.js"

class AccountDetails extends React.Component {
  state = {
    loading: false,
    customerDetails: null
  };

  componentDidMount() {
    console.log('componentDidMount');
    this.handleLoad();
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
    this.handleCancel();
  }

  handleLoad = async () => {
    console.log('handleLoad');
    this.setState({
      loading: true
    });
    const customerDetails = await getCustomerDetails()
    this.setState({
      loading: false,
      customerDetails: customerDetails
    });
  };

  handleCancel = () => {
    console.log('handleCancel');
    this.setState({
      loading: false
    });
  };

  render() {
    const { loading, customerDetails } = this.state;
    console.log('render', this.state);
    return (
      <Layout>
        <h1>Account Details</h1>

        <div>
          <h3>{(loading && "Loading...") || "Not loading"}</h3>
        </div>

        <ul>
          <li><strong>Email: </strong>{customerDetails ? customerDetails.email : 'Loading...'}</li>
          <li><strong>Company: </strong>{customerDetails ? customerDetails.company : 'Loading...'}</li>
          <li><strong>First name: </strong>{customerDetails ? customerDetails.first_name : 'Loading...'}</li>
          <li><strong>Last name: </strong>{customerDetails ? customerDetails.last_name : 'Loading...'}</li>
          <li><strong>Phone: </strong>{customerDetails ? customerDetails.phone : 'Loading...'}</li>
        </ul>

      </Layout>
    );
  }
}


export default AccountDetails

// {isLoggedIn ? (
//   <LogoutButton onClick={this.handleLogoutClick} />
// ) : (
//   <LoginButton onClick={this.handleLoginClick} />
// )}
