import React from 'react'
import { navigate } from "@reach/router"

import Layout from "../common/layout"
import { isCustomerLoggedIn } from "./api.js"

class PrivateRoute extends React.Component {

  // constructor(props) {
  //   super(props);
 
  //   this.state = {
  //     isShow: true,
  //   };
  // }

  state = {
    loginVerified: false,
    loginStatus: null
  };

  async componentDidMount() {
    const loginStatus = await isCustomerLoggedIn()
    this.setState({
      loginVerified: true,
      loginStatus: loginStatus
    });
  }

  render() {
    const { component: Component, location, ...rest } = this.props
    const { loading, loginVerified, loginStatus } = this.state;
    if (loginVerified) {
      if (loginStatus) {
        return <Component {...rest} />
      } else {
        navigate("/account/login")
        return null
      }
    } else {
      return (
        <div>Private Route Verification...</div>
      )
    }
  }
}

export default PrivateRoute