import React from 'react'
import { navigate } from "@reach/router"
import { Link } from 'gatsby';

import { isCustomerLoggedIn } from './api.js'

async function redirectOnEnter() {
  const loginStatus = await isCustomerLoggedIn();
  if (loginStatus) navigate('/account/orders')
  else navigate('/account/login')
}

const AccountIndex = () => {
  console.log('AccountIndex  shown');
  redirectOnEnter();
  return (<></>)
}

export default AccountIndex;