import { Link } from 'gatsby'
import React from 'react'

import Layout from "../common/layout"

const AccountOrders = () => {
  return (
    <Layout>
      <h1>Orders</h1>
      <Link to="/account">Account Index</Link>
    </Layout>
  )
}

export default AccountOrders;