import { Link } from 'gatsby'
import React from 'react'

import Layout from "../common/layout"

const AccountLogin = () => {
  console.log('AccountLogin shown');
  // TODO: If logged in already, redirect to orders
  return (
    <Layout>
      <h1>Login</h1>
      <Link to="/account">Account Index</Link>
    </Layout>
  )
}

export default AccountLogin;