import React from 'react'

import Layout from "../common/layout"

const AccountLogin = () => {
  return (
    <Layout>
      <h1>Not found</h1>
    </Layout>
  )
}

export default AccountLogin;