async function isCustomerLoggedIn() {
  return new Promise( async ( resolve, reject ) => {
    try {
      const loginStatus = await (await fetch('/api/account/login-status')).json()
      resolve(loginStatus.status)
    } catch (error) {
      resolve(0)
    }
  })
}

async function getCustomerDetails() {
  return new Promise( async ( resolve, reject ) => {
    try {
      const response = await (await fetch('/api/account/details')).json()
      resolve(response.data)
    } catch (error) {
      resolve(0)
    }
  })
}

exports.isCustomerLoggedIn = isCustomerLoggedIn
exports.getCustomerDetails = getCustomerDetails