import React from 'react'
import { Router } from "@reach/router"

import Index from "../components/account/index"
import Login from "../components/account/login"
import Orders from "../components/account/orders"
import Details from "../components/account/details"
import NotFound from "../components/account/not-found"
import PrivateRoute from "../components/account/private-route"

const AccountPage = () => {
  return (
    <Router basepath="/account">
      <PrivateRoute path="/orders" component={Orders} />
      <PrivateRoute path="/details" component={Details} />
      <Login path="/login" />
      <Index path="/" />
      <NotFound default />
    </Router>
  )
}

export default AccountPage